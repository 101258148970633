import React, {Component} from 'react';
import styles from './ItemInfo.module.css';

class ItemInfo extends Component {

  render () {

    const {item, sold_at, buyer, supported_until, license, amount, support_amount} = this.props.itemData;

    const date_today = new Date();
    const date_supported = new Date(supported_until);

    const badge = (date_today < date_supported) ? <span className="badge badge-success">Valid</span> : <span className="badge badge-danger">Expired</span>
    const user_url = 'https://themeforest.net/user/' + buyer;

    return (
        <div className="alert alert-empty">
            <div className="info">
                <div className={styles.item}><strong>Item:</strong> {item.name}</div>
                <div className={styles.item}><strong>Sold at:</strong> {(new Date(sold_at)).toDateString()}</div>
                <div className={styles.item}><strong>Buyer:</strong> <a href={user_url} target="_blank" rel="noopener noreferrer">{buyer}</a></div>
                <div className={styles.item}><strong>Supported until:</strong> {(new Date(supported_until)).toDateString()} {badge}</div>
                <div className={styles.item}><strong>License:</strong> {license}</div>
                <div className={styles.item}><strong>Price:</strong> ${parseFloat(amount) + parseFloat(support_amount)}</div>
            </div>
        </div>
    )
  }

}

export default ItemInfo;
