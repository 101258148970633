import React, {Component} from 'react';
import './App.css';
import 'bootstrap/dist/css/bootstrap.css';
import VerifyForm from './components/VerifyForm';
import { HashRouter as Router, Route } from "react-router-dom";

class App extends Component {

  render () {

    return (
        <Router>
            <Route path = "/purchase-code/:code" children = {this.verifyCode} />
        </Router>
    )
  }

  verifyCode = ({ match }) => {

    if(!match) return <VerifyForm />

    const { code } = match.params
    return <VerifyForm code = {code} />
  }
}

export default App;
