import React, {Component} from 'react'
import VerifyItem from './VerifyItem'

const ENVATO_ACCOUNTS = [
{
  'name': 'dedalx',
  'key': 'h7PrZsK4Kq8QGTEMSSKSQuiODV57BEWn'
},
{
  'name': 'creanncy',
  'key': '4bsh4a6EwTrqU1clnQozJ223BLdrOxLc'
}];


class VerifyForm extends Component {

  state = {
    purchaseCode: this.props.code,
    tokenkey: ENVATO_ACCOUNTS[0].key
  }

  render () {

    const key = this.state.purchaseCode+this.state.tokenkey;

    return (
      <div className="container">
        <div className="row">
          <div className="col-md-6 mx-auto">
            <h1 className="mb-4">Purchase code verification</h1>
            <div className="form-group mb-5">
              <label>Envato purchase code for verification:</label>
              <label className="float-right">Account:</label>
              <div className="row">
                <div className="col-md-8">
                  <input type="text" className="form-control" placeholder="079eevfb-6af8-4309-9dda-1a5dc24b188a" value={this.state.purchaseCode} onChange={this.onChangePurchaseCode}/>
                </div>
                <div className="col-md-4">
                <select className="custom-select" onChange={this.onChangeAccount}>
                {ENVATO_ACCOUNTS.map((token) => {
                   return (<option key={token.key} value={token.key}>{token.name}</option>)
                })}
                </select>
              </div>
              </div>
            </div>
            <VerifyItem code={this.state.purchaseCode} tokenkey={this.state.tokenkey} key={key} />
          </div>
        </div>
      </div>
    )
  }

  // onChange account
  onChangeAccount = (event) => {
    this.setState({
      tokenkey: event.target.value
    });
  }

  // onChange purchase code field
  onChangePurchaseCode = (event) => {

    let purchaseCode = event.target.value;

    this.setState({
      purchaseCode: purchaseCode
    });

  }
}

export default VerifyForm;
