import React, {Component} from 'react'
import { List } from 'react-content-loader'
import ItemInfo from './ItemInfo'

const CONNECTION_TIMEOUT = 10000; // 10 sec

// Envato API auth tokens with account names

/*
  TEST PURCHASE CODES:
  dedalx:          079eeffb-6af8-4309-9ada-1a5dc24b188a
  creanncy:        c877e499-93ef-4813-8384-394b98777542
  Expired support: f5436a27-a651-46c1-96bc-40578de92556
*/

// Use Axios for connection
const axios = require('axios');

class VerifyItem extends Component {

  state = {
    requestData: null,
    requestState: null
  }

  componentDidMount() {
    this.verifyPurchaseCode(this.props.code, this.props.tokenkey);
  }

  render () {

    let itemInfo = '';
    let requestState = this.state.requestState;

    if(this.state.requestData !== null) {
      itemInfo = <ItemInfo itemData={this.state.requestData}/>
    }

    switch(requestState) {
      case 'loading':
        // https://github.com/danilowoz/react-content-loader
        itemInfo = <div className="alert alert-empty pt-4"><List /></div>
        break;

      case 'notvalid':
        itemInfo = <div className="alert alert-warning">Please enter purchase code in correct format.</div>
        break;

      case '404':
        itemInfo = <div className="alert alert-warning">Purchase code does not exist for this account.</div>
        break;

      case 'error':
        itemInfo = <div className="alert alert-danger">Something goes wrong with Envato API.</div>
        break;

      default:
      break;

    }

    return (
      <div>
        {itemInfo}
      </div>
    )
  }

  // Verify purchase code
  verifyPurchaseCode = (purchaseCode, authToken) => {

    const purchase_code_regex = /^(\w{8})-((\w{4})-){3}(\w{12})$/;

    // Validate purchase code format
    if((purchase_code_regex.exec(purchaseCode)) !== null) {
      // Show loading animation
      this.setState({
        requestState: 'loading'
      });

      // Init connection to API
      const axios_instance = axios.create({
        baseURL: 'https://api.envato.com/v3/market',
        timeout: CONNECTION_TIMEOUT,
        method: 'POST',
        headers: {
          'Authorization': 'Bearer ' + authToken,
          'Content-Type': 'application/x-www-form-urlencoded'
        }
      })

      axios_instance.get('/author/sale?code=' + purchaseCode, {
        validateStatus: function (status) {
          return true;
        }
      }).then( (response) => {

        console.log(response);

        // Purchase code not found
        if(response.status === 404) {
          this.setState({ // this does not available
            requestData: null,
            requestState: '404'
          });
        }
        // Purchase code valid response
        else if(response.status === 200) {
          this.setState({ // this does not available
            requestData: response.data,
            requestState: null
          });
        }
        // Some error on Envato API
        else {
          this.setState({ // this does not available
            requestData: response.data,
            requestState: 'error'
          });
        }
      })
      .catch( (error) => {
        // handle error
        console.log(error);

        this.setState({
          requestData: null,
          requestState: 'error'
        });
      })
      .finally( () => {
        // always executed
      });

    } else {
      this.setState({ // this does not available
        requestData: null,
        requestState: 'notvalid'
      });
    }

  }
}

export default VerifyItem;
